import { mapState } from "vuex";
// 导入api接口
import {
  getOrderList,
  cancleOrderAll,
  cancleOrderLine,
  addCart,
  exportOrderSap,
  exportOrder,
} from "./api.js";
import navUtil from "@/components/global/CustomizeNav/util";
import ProductItem from "@/components/global/OrderDetail/ProductItem";
import Util from "@/utils/utils";
export default {
  data() {
    return {
      cancelText: '取消订单', //  取消提示文字
      img: require("././../../assets/noticeList/编组 13@3x.png"),
      navList: [],//导航数据
      spinOrderExport: false,
      spinShenExport: false,
      pageLoadFlag: false, //load标志
      creatDate: 1,
      isView: true,
      dateFormat: "YYYY-MM-DD",
      orderList: [],
      breadcrumbData: [
        {
          path: "/index",
          name: "index",
          title: "首页",
        },
        {
          path: "/order/cis",
          name: "/order",
          title: "海信订单明细",
        },
      ],
      postDate: {
        pageNo: 1,
        searchStr: "",
        orderTypeCode: "",
        sapOrderStatus: "",
        matklId: null,
        orgId: null,
        status: "",
        orderBy: "desc",
        beginDate: "",
        endDate: "",
        timeFrame: "1",
        timeAuditFrame: "",
        sapBeginDate: "",
        sapEndDate: "",
        weekName: "",
        orderTimeGroup: "7,,", //默认最近一个月
      },
      totalPages: "",
      total: 0,
      currentPage: "",
      searchOrderNo: "", //订单号
      visible: false, //是否显示取消订单弹框
      ModalText: "确定取消订单？",
      confirmLoading: false,
      cancleData: { searchStr: "" },
      nolistImg: require("@/assets/order/noList.png"),
      showList: false,
      orderTypeId: "",
      ordertypeCancel: 1,
      shenLoading: false,
      orderLoading: false,
      isFenxiaoSign: "",
      isFenxiaoSignActive: "",
      isChange: false,
      dataArr: [],
      isTrue: false,
      ids: [],
      nums: []
    };
  },
  components: {
    ProductItem,
  },
  computed: {
    ...mapState({
      //处理后的筛选数据list
      filList: (state) => state.filtrate.filList,
      isFenxiaoOrder: (state) => state.user.userInfo.account.marketModels,
      account: (state) => state.user.userInfo.account.account,
      //筛选组件加载loadding
      filterLoadFlag: (state) => state.filtrate.isLoading,
      //用于查询列表的筛选配置字段
      filterConfigStr: (state) => state.filtrate.filterConfigStr,
    }),
  },
  mounted() {
    console.log('filList', this.filList)
    //头导航数组
    this.navList = navUtil.orderList;
    console.log('navList', this.navList)
    if (
      (this.isFenxiaoOrder.indexOf("17452") !== -1 ||
        this.isFenxiaoOrder.indexOf("17453") !== -1) &&
      this.isFenxiaoOrder.indexOf("17451") == -1
    ) {
      this.isFenxiaoSign = "2";
    } else if (
      this.isFenxiaoOrder.indexOf("17451") !== -1 &&
      this.isFenxiaoOrder.indexOf("17452") == -1 &&
      this.isFenxiaoOrder.indexOf("17453") == -1
    ) {
      this.isFenxiaoSign = "1";
      // this.$router.push({
      //   path: "/order/dms",
      // });
    } else if (
      (this.isFenxiaoOrder.indexOf("17451") !== -1 &&
        this.isFenxiaoOrder.indexOf("17452") !== -1) ||
      this.isFenxiaoOrder.indexOf("17453") !== -1
    ) {
      this.isFenxiaoSign = "3";
      this.isFenxiaoSignActive = "2";
    }
    // //筛选后续操作的入参 加后台已定义的 formCode 便于筛选后查询
    this.filterFrom = { ...this.filterFrom, formCode: this.filterConfigStr ? this.filterConfigStr : '' };

    //海信采购
    // this.$store.commit("filtrate/SET_CONFIG_STR", {
    //   toPath: this.$route,
    //   marketModels: "17452",
    // });
    //   this.isChange = true;
    //   //请求筛选公共接口 获取筛选数据
    //   this.$store.dispatch("filtrate/systemConfigFormItem").then(()=>{
    //     this.isChange = false
    // });

    // if (this.orderList.length == 0) {
    //   this.getOrderLi();
    // }
  },
  methods: {
    // 去支付
    handleToPay(item){
      if(item.orderServiceFeeList && item.orderServiceFeeList.length > 0) {
        let feeCode = item.orderServiceFeeList[0].feeCode
        this.$router.push({
          path: "/serviceFeeModule/payDetail",
          query: {
            id:feeCode,
            batch:0,
            b2bOrderCode: item.orderCode,
            orgName: '订单号',
          }
        });
      } else {
        this.$message.warning('暂无待支付订单')
      }
    },
    // 更改搜索数据框
    dataChange(goodsItem, e) {
      goodsItem.checked = true
      this.$forceUpdate()
    },
    // 多选框更改
    onChange(goodsItem, e, ppIntem) {
      goodsItem.checked = e.target.checked
      let cherkNum = 0
      ppIntem.forEach(item => {
        item.data.forEach(gooItem => {

          if (gooItem.checked) {
            cherkNum += 1

          }
        })
      })
      if(cherkNum > 0 ) {
        this.cancelText = '确认取消'
      } else {
        this.cancelText = '取消订单'
      }
      this.$forceUpdate()
    },
    postSpinTrue(value) {
      this.pageLoadFlag = value;
    },
    handleTab(type) {
      if (this.isFenxiaoSign == "3") {
        this.isFenxiaoSignActive = type;
      }
      this.isDataViewMoreFun();
      if (type == 1) {
        //渠道采购
        // this.getDmsOrderli();
        this.isGetDmsFlag = true;
        this.$router.push({
          path: "/order/dms",
        });
      } else {
      }
    },
    //判断数据是否有选中 赋值class
    isDataViewMoreFun() {
      // isView?'type-wrap-active':''
      let cls = "";
      let count = 0;
      for (let itemL1 of this.filList) {
        for (let itemL2 of itemL1.list) {
          if (itemL2.isActive && itemL2.name != "全部") {
            count++;
          }
        }
      }
      if (count > 0) {
        cls = "fil-checked";
      }
      return cls;
    },
    // 再来一单
    againOrder(id, code) {
      let carts = id;
      let versions = "";
      let orgAndGroup = "";
      let purchaseType = "";
      this.orderList.forEach((item) => {
        if (item.id == id) {
          item.items.forEach((goodsItem) => {
            orgAndGroup = item.orgId + "-" + goodsItem.matklId;
          });
        }
      });
      // versions = versions.substring(0,versions.length-1)
      if (code == "应急订单") {
        purchaseType = "1";
      } else if (code == "常规订单") {
        purchaseType = "2";
      }
      this.$router.push({
        path: "/confirm/orderAgain",
        query: {
          carts: carts,
          versions: versions,
          orgAndGroup: orgAndGroup,
          purchaseType: purchaseType,
        },
      });
    },
    toDetail(proCode, orgId) {
      this.$router.push({
        path: "/product/detail",
        query: { productCode: proCode, orgId: orgId },
      });
    },
    // 订单导出
    downloadFile(params) {
      var blob = params;
      if (
        "download" in document.createElement("a") &&
        navigator.userAgent.indexOf("Edge") == -1
      ) {
        var elink = document.createElement("a");
        elink.download = "采购订单明细.xls";
        elink.style.display = "none";
        elink.href = URL.createObjectURL(blob);
        document.body.appendChild(elink);
        elink.click();
        URL.revokeObjectURL(elink.href); // 释放URL 对象
        document.body.removeChild(elink);
      } else {
        navigator.msSaveBlob(blob, "采购订单明细.xls");
      }
    },
    // 审核单导出
    downloadFileShen(params) {
      var blob = params;
      if (
        "download" in document.createElement("a") &&
        navigator.userAgent.indexOf("Edge") == -1
      ) {
        var elink = document.createElement("a");
        elink.download = "采购订单明细.xls";
        elink.style.display = "none";
        elink.href = URL.createObjectURL(blob);
        document.body.appendChild(elink);
        elink.click();
        URL.revokeObjectURL(elink.href); // 释放URL 对象
        document.body.removeChild(elink);
      } else {
        navigator.msSaveBlob(blob, "采购订单明细.xls");
      }
    },
    shaiClick() {
      this.isView = !this.isView;
    },
    // 搜索
    onSearchOrderCode() {
      this.postDate.pageNo = 1;
      this.postDate.timeFrame = 1;
      this.postDate.searchStr = this.searchOrderNo;
      this.getOrderLi();
    },

    // 订单导出
    exportOrder() {
      let exportObj = this.postDate;
      delete exportObj.orderBy;
      this.orderLoading = true;
      exportOrder(exportObj)
        .then((res) => {
          Util.blobToJson(res.data).then(content => {
            if (content && content.msg == 'success') {
              this.$message.success('下载成功，请到下载中心查看!')
            }
          }).catch(err => {
            Util.downloadFile(res.data, '采购订单明细.xls')
          }).finally(() => {
            this.orderLoading = false;
          })
        })
        .catch((error) => {
          this.orderLoading = false;
          console.log("订单导出", error);
        });
    },
    // 审核单导出
    exportOrderSap() {
      let exportObj = this.postDate;
      delete exportObj.orderBy;
      this.shenLoading = true;
      exportOrderSap(exportObj)
        .then((res) => {
          Util.blobToJson(res.data).then(content => {
            if (content && content.msg == 'success') {
              this.$message.success('下载成功，请到下载中心查看!')
            }
          }).catch(err => {
            Util.downloadFile(res.data, '审核订单明细.xls')
          }).finally(() => {
            this.shenLoading = false;
          })
        })
        .catch((error) => {
          this.shenLoading = false;
          console.log("审核单导出", error);
        });
    },
    // 加入购物车
    addCart(proid, orgid) {
      let data = { productId: proid, orgId: orgid, num: 1 };
      addCart(data)
        .then((res) => {
          if (res.data.code == 0) {
            this.$message.success("加入购物车成功");
            //更新购物车
            this.$store.dispatch("user/getCartNum");
            this.$store.dispatch("user/getCartInfoList");
          } else {
            this.$message.warning(res.data.msg)
          }
        })
        .catch((error) => {
          this.$message.warning("加入购物车失败");
          console.log("加入购物车", error);
        });
    },
    // 取消行订单
    cancleOrderLine(orderType, statusCode, id) {
      let obj = {
        orderTypeId: orderType,
        orderStatusCode: statusCode,
        id: id,
        type: 2,
      };
      this.cancelOrder(obj);
    },
    cancelOrder(item) {
      const orderType = item.orderTypeId;
      const states = item.orderStatusCode;
      this.orderTypeId = item.id;
      this.ordertypeCancel = item.type;
      if (
        orderType == "8311" &&
        (states == "ALREADYPLANPRODUCT" ||
          states == "ARRANGEDPRODUCT" ||
          states == "UNCHKED" ||
          states == "WAITDELIVER" ||
          states == "PARTCHECKED")
      ) {
        this.$confirm({
          title: "取消提醒",
          content:
            "取消‘评审通过’,‘已安排生产’,‘待排发货计划’,‘待发货’,‘发货中’状态的常规订单，会判定为商家违约，请确认是否取消？",
          onOk: () => {
            this.visible = true;
          },
          onCancel() { },
        });
      } else {
        this.visible = true;
      }
    },
    getReason(value) {
      this.cancelReason = value;
      this.cancleSubmit(this.orderTypeId, this.ordertypeCancel);
    },
    // 取消订单方法
    cancleSubmit(id, type) {
      this.pageLoadFlag = true;
      // let obj = { id: id, cancelReason: this.cancelReason };
      let obj = {
        id: id,
        cancelReason: this.cancelReason,
        ids: this.ids.join(','),
        nums: this.nums.join(',')
      }
      if (type == 2) {
        cancleOrderLine(obj)
          .then((res) => {
            if (res.data.type == "Y") {
              this.pageLoadFlag = false;
              this.$message.success("订单取消成功");
              this.getOrderLi();
            } else {
              this.pageLoadFlag = false;
              this.$message.warning(res.data.msg, 5);
              this.getOrderLi();
            }
          })
          .catch((error) => {
            this.pageLoadFlag = false;
            console.log(error);
            this.$message.warning("订单取消失败");
          });
      } else {
        cancleOrderAll(obj)
          .then((res) => {
            if (res.data.type == "Y") {
              this.$message.success("订单取消成功");
              this.getOrderLi();
            } else {
              this.pageLoadFlag = false;
              this.$message.warning(res.data.msg, 5);
              this.getOrderLi();
            }
          })
          .catch((error) => {
            this.pageLoadFlag = false;
            console.log(error);
            this.$message.warning("订单取消失败");
          });
      }
    },
    // 判断组合购是否符合 条件
    checkCombinationPurchase(proItem) {
      let standardValArr = [] // 每个组实际要求数量
      let currValArr = [] // 每个组购买总数
      let multipleArr = [] // 每个组购买总数与实际要求数量成比例
      proItem.dataArr.forEach((aaitem) => {
        aaitem.forEach(val => {
          val.numOrder = 0
          val.data.forEach(item => {
            if (item.checked) {
              val.numOrder += item.cherkNum
            }
          })
          standardValArr.push(val.packageNum)
          currValArr.push(val.numOrder)
          multipleArr.push(val.numOrder % val.packageNum)
        })

      })
      for (let i = 0; i < currValArr.length; i++) {
        if (currValArr[i] == 0) {
          return false
        }
      }
      for (let i = 0; i < multipleArr.length; i++) {
        if (multipleArr[i] != 0) {
          return false
        }
      }
      let multiple = currValArr[0] / standardValArr[0] //先生成一个参考比例
      // 判断每组购买总数比例是否与实际要求比例相同
      for (let i = 0; i < currValArr.length; i++) {
        if (currValArr[i] / standardValArr[i] != multiple) {
          return false
        }
      }
      return true
    },
    cancleOrderAlle(orderType, statusCode, id) {
      //取消整单
      let obj = {
        orderTypeId: orderType,
        orderStatusCode: statusCode,
        id: id,
        type: 1,
      };
      this.cancelOrder(obj);
    },
    // 取消整单
    cancleOrderAllezuhe(orderType, statusCode, id, dataItem,cancelText) {
      if(dataItem.cancelText== '取消订单') {
        dataItem.cancelText = '确认取消'
        this.$forceUpdate()
        return
      }
      this.ids = []
      this.nums = []
      // 新的取消逻辑 不允许修改数量取消 只能整单取消
      dataItem.dataArr.forEach(element => {
        element.forEach(item => {
          item.data.forEach(aaa => {
            this.ids.push(aaa.itemId)
            this.nums.push(aaa.cherkNum)
          })
        })
      });
      let obj = {
        orderTypeId: orderType,
        orderStatusCode: statusCode,
        id: id,
        type: 1,
        ids: this.ids,
        nums: this.nums
      };
      this.cancelOrder(obj);
      return;

      // 以下是之前的取消逻辑 可以等比例取消
      // this.isTrue = false
      // //取消整单
      // dataItem.dataArr.forEach(element => {
      //   element.forEach(item => {
      //     item.cherkNum = 0
      //     item.packageNum = 0
      //     item.data.forEach(aaa => {
      //       item.packageNum = aaa.packageNum
      //       if (aaa.checked) {
      //         item.cherkNum += aaa.cherkNum
      //         this.ids.push(aaa.itemId)
      //         this.nums.push(aaa.cherkNum)
      //       }
      //     })
      //
      //     this.isTrue = this.checkCombinationPurchase(dataItem)
      //   })
      // });
      // if (this.isTrue) {
      //   let obj = {
      //     orderTypeId: orderType,
      //     orderStatusCode: statusCode,
      //     id: id,
      //     type: 1,
      //     ids: this.ids,
      //     nums: this.nums
      //   };
      //   this.cancelOrder(obj);
      // } else {
      //   this.$message.warning('取消数量不符合组合购比例，请重新选择')
      // }
    },
    handleOk() {
      this.confirmLoading = true;
      cancleOrderAll(this.cancleData)
        .then((res) => {
          if (res.data.type == "Y") {
            this.confirmLoading = false;
            this.getOrderLi();
          } else {
            this.$message(res.data.msg,5)
            this.getOrderLi();
          }
        })
        .catch((error) => {
          console.log("取消失败", error);
        });
    },
    handleCancel() {
      this.visible = false;
    },
    // 时间排序
    changeFang() {
      if (this.creatDate == 0) {
        this.creatDate = 1;
        this.postDate.orderBy = "desc";
      } else if (this.creatDate == 1) {
        this.creatDate = 2;
        this.postDate.orderBy = "asc";
      } else {
        this.creatDate = 0;
        this.postDate.orderBy = "";
      }
      this.getOrderLi();
    },
    onFilterChange(checkedObj) {
      //合并入参请求对象
      this.postDate = { ...this.postDate, ...checkedObj[0], pageNo: 1 };
      //查询列表
      this.getOrderLi();
    },
    goTo(id,item) {
      // 如果是样机订单 跳转样机订单详情
      if( item.orderType == '8320') {
        let path = '/prototyZone/detailHX?id='+id
        let routeUrl = this.$router.resolve({
          path: path,
        });
        window.open(routeUrl.href, "_blank");
        return
      } else {
        let path = item.orderTypeName == '工程订单'?'/order/detailsEngineering?id='+id:'/order/detail?id='+id
        let routeUrl = this.$router.resolve({
          path: path,
        });
        window.open(routeUrl.href, "_blank");
      }

    },

    goToP(path) {
      // this.$router.push(path);
      let routeUrl = this.$router.resolve({
        path: path,
      });
      window.open(routeUrl.href, "_blank");
    },
    getOrderLi() {
      this.pageLoadFlag = true;
      this.showList = false;
      if (this.$route.query.projectCode) {
        this.postDate.titleFilter = 'referenceEngineer.projectCode'
        this.postDate.titleFilterValue = this.$route.query.projectCode
      }
      this.postDate.status = ''
      getOrderList(this.postDate)
        .then((res) => {
          this.pageLoadFlag = false;
          if (res.data.orderHeaderList && res.data.orderHeaderList.length > 0) {
            this.orderList = res.data.orderHeaderList;
            this.orderList.forEach((item) => {
              if(item.orderServiceFeeList && item.orderServiceFeeList.length > 0) {
                let orderServideFeeData = item.orderServiceFeeList[0]
                let nowDate = new Date().getTime()
                let payExpireDateStr = orderServideFeeData.createdDateEnd
                item.orderServiceFeeTime = payExpireDateStr - nowDate
                item.leftTime = 0
                let  date = new Date();
                let  now = date.getTime();
                //设置截止时间
                // var endDate = new Date("2020-1-22 23:23:23");
                var end = orderServideFeeData.createdDateEnd;
                //时间差
                item.leftTime = end - now;
                //定义变量 d,h,m,s保存倒计时的时间
                if (item.leftTime >= 0) {
                  item.d = Math.floor(item.leftTime / 1000 / 60 / 60 / 24);
                  item.h = Math.floor(item.leftTime / 1000 / 60 / 60 % 24);
                  item.m = Math.floor(item.leftTime / 1000 / 60 % 60) < 10?'0'+ Math.floor(item.leftTime / 1000 / 60 % 60):Math.floor(item.leftTime / 1000 / 60 % 60);
                  item.s = Math.floor(item.leftTime / 1000 % 60)< 10?'0'+ Math.floor(item.leftTime / 1000 % 60):Math.floor(item.leftTime / 1000 % 60);
                  item.sum_h = this.d * 24 + this.h
                }
              }
              if (item.productGroupFlag == 'Y') {
                item.cancelText = '取消订单'
                item.dataArr = []
                item.dataArr.push(this.changeData(item.items))
                item.dataArr.forEach(proItem => {
                  proItem.forEach(aaa => {
                    aaa.data.forEach(bbb => {
                      bbb.checked = false
                      bbb.cherkNum = bbb.qty-bbb.erpQty-bbb.cancelQty-bbb.timeoutQty
                    })
                  })
                })
                this.$forceUpdate()
              }
              item.items.forEach((goodsItem) => {
                if (goodsItem.billPrice.toString().indexOf(".") == -1) {
                  goodsItem.billPrice = Number(goodsItem.billPrice).toFixed(2);
                }
                if (goodsItem.basePrice.toString().indexOf(".") == -1) {
                  goodsItem.basePrice = Number(goodsItem.basePrice).toFixed(2);
                }

              });

            });
            this.totalPages = res.data.totalPages;
            this.total = res.data.totalCount
            this.currentPage = res.data.currentPage;
            this.showList = true;
          } else {
            this.total = res.data.totalCount
            this.orderList = [];
            this.showList = true;
          }
        })
        .catch((error) => {
          this.pageLoadFlag = false;
          console.log("列表", error);
        });
    },

    //组合购数据更改
    changeData(items) {
      var map = {},
        dest = [];
      for (var i = 0; i < items.length; i++) {
        var ai = items[i];
        if (!map[ai.productGroup]) {
          dest.push({
            productGroup: ai.productGroup,
            name: ai.productGroupRemark,
            num: ai.packageNum,
            data: [ai]
          });
          map[ai.productGroup] = ai;
        } else {
          for (var j = 0; j < dest.length; j++) {
            var dj = dest[j];
            if (dj.productGroup == ai.productGroup) {
              dj.data.push(ai);
              break;
            }
          }
        }
      }
      return dest;
    },
    clickMore() {
      this.pageLoadFlag = true;
      this.postDate.pageNo = this.postDate.pageNo + 1;
      getOrderList(this.postDate)
        .then((res) => {
          this.orderList = this.orderList.concat(res.data.orderHeaderList);
          this.orderList.forEach((item) => {
            item.dataArr = []
            if (item.productGroupFlag == 'Y') {
              item.cancelText = '取消订单'
              item.dataArr = []
              item.dataArr.push(this.changeData(item.items))
              item.dataArr.forEach(proItem => {
                proItem.forEach(aaa => {
                  aaa.data.forEach(bbb => {
                    bbb.checked = false
                    bbb.cherkNum = bbb.qty-bbb.erpQty-bbb.cancelQty-bbb.timeoutQty
                  })
                })
              })
              this.$forceUpdate()
            }
            item.items.forEach((goodsItem) => {
              if (goodsItem.billPrice.toString().indexOf(".") == -1) {
                goodsItem.billPrice = Number(goodsItem.billPrice).toFixed(2);
              }
              if (goodsItem.basePrice.toString().indexOf(".") == -1) {
                goodsItem.basePrice = Number(goodsItem.basePrice).toFixed(2);
              }
            });
          });
          this.pageLoadFlag = false;
          this.totalPages = res.data.totalPages;
          this.total = res.data.totalCount;
          this.currentPage = res.data.currentPage;
        })
        .catch((error) => {
          console.log("下拉", error);
        });
    },
  },
};
